$new-white: rgb(255, 255, 255);
$light-grey: rgb(128, 128, 128);
$text-margin: 5px;

@function responsive-font($min-size, $max-size) {
  // Calculate the difference between max and min sizes without units
  $size-difference: strip-unit($max-size - $min-size);

  // Calculate the viewport scaling factor, ensuring units match correctly
  $viewport-scaling: calc(#{$size-difference} * ((100vw - 320px) / (1200px - 320px)));

  // Return the clamp function with the calculated values
  @return clamp(#{$min-size}, calc(#{$min-size} + #{$viewport-scaling}), #{$max-size});
}

.card-container {
  width: 100%;
  max-width: 190px;
  aspect-ratio: 1 / 0.9;
  margin-left: 8px;
  margin-bottom: 15px;
  color: #000; // Avoid blue text when wrapped in an anchor tag.
  transition: transform 0.5s ease-in-out;
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;

  @media (max-width: 475px) {
    max-width: 90px;
    margin-left: 0px;
  }

  @media (max-width: 900px) {
    max-width: 155px;
  }

  &.flipped {
    .front {
      transform: rotateY(180deg);
    }

    .back {
      transform: rotateY(0deg);
    }
  }

  .front,
  .back {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: block;
    background: $new-white;
    // this adds perspective drop shadow
    // box-shadow: 10px 10px 5px rgb(95, 77, 99);
    //just bottom drop shadow
    box-shadow: 0 5px 10px -4px rgba(0, 0, 0, 0.6);

    position: absolute;
    border-radius: 10px;

    // border: 2px solid black;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
    /* front tile styles go here! */
  }

  .back {
    background: $new-white;
    transform: rotateY(-180deg);
    // padding: 10px;
    padding-right: 5px;
    // display: -webkit-box;
    // -webkit-line-clamp: 3; // Number of lines to show
    // -webkit-box-orient: vertical;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: left;
    align-items: left;
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 55%;

    .card-image {
      width: 100%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      display: block;
      object-fit: cover;
      object-position: center;
    }

    .placeholder-card-image {
      object-fit: scale-down;
      background-color: #262422;
    }
  }

  .main-area {
    margin-left: 3%;
    // margin-top: $text-margin;
    margin-right: 3%;

    .title {
      font-size: 11px;
      margin-left: $text-margin;
      margin-bottom: 12px;
      color: $light-grey;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; // Number of lines to show
      -webkit-box-orient: vertical;

      @media (max-width: 475px) {
        font-size: 8px; // Minimum font size
      }
    }

    .poster-content {
      height: 100%;
      font-weight: bold;
      margin-left: $text-margin;
      margin-top: 6%;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      // margin-top: 5px;
      -webkit-line-clamp: 1; // Number of lines to show
      -webkit-box-orient: vertical;
      // max-height: calc(1.2em * 3);

      @media (max-width: 475px) {
        font-size: 10px; // Minimum font size
      }

      .date {
        margin-top: $text-margin;
        margin-bottom: 5px;
        font-size: 20px;
      }

      .read-more {
        font-size: 20px;
        position: relative;
        bottom: 0px;
        text-align: right;
      }
    }

    .back-content {
      height: 100%;
      font-weight: bold;
      margin-left: $text-margin;
      font-size: 16px;
      overflow: hidden;
      margin-top: 5%;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7; // Number of lines to show
      -webkit-box-orient: vertical;
      // max-height: calc(1.2em * 3);

      @media (max-width: 475px) {
        font-size: 12px; // Minimum font size
        -webkit-line-clamp: 6; // Adjust the number of lines for smaller screens
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out infinite alternate;
  display: none;
  z-index: 100;
  font-size: 14px;
}

.swipe-icon {
  margin-top: 10px;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .overlay {
    display: block;
    /* Show on mobile devices */
  }
}
