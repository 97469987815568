:root {
  --header-height: auto;
  --container-width: 800px;
  --page-padding: 1.25em;
}



.alert-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #ffc107;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.close-button {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px;
}

.main-header {
  cursor: pointer;
  font-size: medium;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.back-button {
  left: 0px;
  top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #263238;
  padding: 0;
}
