.sentiment-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sentimentLabel {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.emotionLabel {
  font-size: 15px;
  font-weight: bold;
  padding: 6px 14px;
  border-radius: 20px;
  color: var(--text-color, #fff);
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}

.emotions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  max-width: 80%;
}

@media (max-width: 850px) {
  .emotions-container {
    justify-content: flex-start;
    /* Align pills to the start on smaller screens */
  }
}

.risk-description {
  margin-top: 10px;
  padding: 20px 100px 20px;
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1.35;
  max-width: 75%;
  text-align: left;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.3),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

@media (max-width: 850px) {
  .risk-description {
    padding: 15px 10px 15px 20px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
